import { useState, ReactElement, useEffect, useMemo } from 'react';
import { TextLoop } from 'react-text-loop-next';
import {
  BlockTitle,
  BlockSubtitle,
  BlockWrapper,
} from 'styles/components/Title';
import PageWidth from 'styles/components/PageWidth';
import Image from 'components/Image/ResponsiveImage';

const INTERVAL = 3000;

const Carousel = ({ slides }: CarouselType): ReactElement => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(slides[currentSlideIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex(currentValue => {
        const nextSlide =
          currentValue + 1 === slides.length ? 0 : currentValue + 1;

        setCurrentSlide(slides[nextSlide]);

        return nextSlide;
      });
    }, INTERVAL);

    return () => clearInterval(interval);
  }, []);

  if (!currentSlide.image || !currentSlide.text) {
    setCurrentSlideIndex(currentValue => {
      const nextSlide =
        currentValue + 1 === slides.length ? 0 : currentValue + 1;

      return nextSlide;
    });
  }

  const slideText = useMemo(() => slides.map(slide => slide.text), [slides]);

  return (
    <PageWidth>
      <div className="flex relative h-72 md:h-96 overflow-hidden lg:rounded-3xl hover:scale-up">
        <Image
          src={currentSlide.image}
          alt="Crafty Party Box | Girl enjoying craft kit"
          priority
        />

        <div className="pb-11 px-5 mt-auto">
          <BlockWrapper>
            <BlockTitle>
              A Craft Box Full Of{' '}
              <TextLoop interval={INTERVAL}>{slideText}</TextLoop>
            </BlockTitle>
            <BlockSubtitle>
              Themed craft boxes for kids delivered straight to your door 🥳
            </BlockSubtitle>
          </BlockWrapper>
        </div>
      </div>
    </PageWidth>
  );
};

type CarouselType = {
  slides: {
    image: string | StaticImageData;
    text: string;
  }[];
};

export default Carousel;
