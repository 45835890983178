import React, { ReactElement } from 'react';
import Carousel from 'components/Carousel/Carousel';
import SpringBox from 'public/spring-box.jpeg';
import DinosaurPlay from 'public/dinosaur-play.jpeg';
import Puzzles from 'public/Puzzles.jpg';
import Making from 'public/making.jpeg';

const slides = [
  { image: SpringBox, text: 'Creativity' },
  { image: DinosaurPlay, text: 'Wonder' },
  { image: Puzzles, text: 'Learning' },
  { image: Making, text: 'Making' },
];

const CraftBoxPromo = (): ReactElement => <Carousel slides={slides} />;

export default CraftBoxPromo;
